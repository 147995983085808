<template>
  <div class="taskView">
    <div class="banner banner_auto">
      <div class="banner_bg"></div>
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(val, index) in adlist" :key="index" @click="linkUrl(val.url)">
          <img :src="$imageUrl + val.pic" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <van-tabs
      v-model="$route.query.index"
      @change="onChange"
      line-width="60px"
      background="#151d31"
      title-inactive-color="#ffffff"
      title-active-color="#4087f1"
    >
      <van-tab :title="val.name" v-for="(val, index) in filteredTypeList" :key="index"></van-tab>
    </van-tabs>
    <van-cell-group>
      <van-grid :border="false" :column-num="2" :gutter="10">
        <van-grid-item v-for="(val, index) in level_list" v-bind:key="index" @click="onTaskList(val)">
          <van-cell style="text-align: center;">
            <template #title>
              <div class="top" style="color:#ffffff">{{ val.level_name }}</div>
            </template>
            <template #label>
              <p style="font-size: 0.42rem;color:#ffffff">{{ $t('每日任务') }}:{{ val.task_num }}</p>
            </template>
          </van-cell>
        </van-grid-item>
      </van-grid>
    </van-cell-group>
  </div>
</template>

<script>
import { swipe } from "@/api/ad";
import { getLevel, getTaskType } from "@/api/utils";
import { upLevel } from "@/api/users";

export default {
  data() {
    return {
      adlist: [],
      level_list: [],
      list: [],
      page: 1,
      pageSize: 10,
      cate_id: 0,
      active: 1,
      typeList: []
    };
  },
  created() {
    this.getAdList();
    this.GetLevel();
    this.GetTaskType();
  },
  computed: {
    filteredTypeList() {
      return this.typeList.filter(item => item.status === 1);
    },
  },
  mounted() {},
  methods: {
    onClickRight() {
      this.$router.push("/main/public/language");
    },
    onChange() {},
    //获取轮播图资源
    getAdList() {
      swipe({ as_id: 1, lang: this.$lang })
        .then(response => {
          this.adlist = response.result;
        })
        .catch(error => {});
    },
    //获取等级
    GetLevel() {
      getLevel({})
        .then(response => {
          this.level_list = response.result;
        })
        .catch(error => {});
    },
    //获取任务分类
    GetTaskType() {
      getTaskType({})
        .then(response => {
          this.typeList = response.result;
        })
        .catch(error => {});
    },
    onTaskList(data) {
      
      if (this.$store.getters.userinfo.level != data.level_id) {
        return;
      }
      this.$router.push({
        path: "/main/tasklist/list",
        query: {
          type: this.typeList[this.$route.query.index].id,
          level: data.level_id
        }
      });
    }
  }
};
</script>

<style lang="stylus">
.taskView
  .banner_auto
    position: relative
    .van-swipe
      img
        display: block
        box-sizing: border-box
        width: 100%
        background-color: #fff
        pointer-events: none
    .banner_bg
      position: absolute
      top: 0
      height: 3.33rem
      background: $mainColor
      width: 100%
  .van-tabs__line
    background-color: #4087f1
  .van-cell-group
    background: transparent
    margin-top: 0.3rem
  .van-cell
    font-size: 1rem
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #ccd7e9, #7085a5)
    background-size: contain
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2)
    border-radius: 0.15rem
    padding: 0.5rem 0.3rem
    p
      margin: 5px 0 0 0
    .top
      font-size: 0.5rem
      margin-bottom: 0.4rem
    .bottom
      color: #ffffff
      display: flex
      justify-content: space-between
      align-items: flex-end
  .van-cell+.van-cell
    margin-top: 0.325rem
  .van-grid-item:nth-of-type(2) .van-cell
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #fd9e02, #d86a25)
    background-size: contain
  .van-grid-item:nth-of-type(3) .van-cell
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #3044b3, #273580)
    background-size: contain
  .van-grid-item:nth-of-type(4) .van-cell
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #31b489, #3e91b8)
    background-size: contain
  .van-grid-item:nth-of-type(5) .van-cell
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #47484c, #1f2025)
    background-size: contain
  .van-grid-item:nth-of-type(6) .van-cell
    background: url('../../assets/images/vip_new_bg.png') no-repeat 1.2rem, linear-gradient(60deg, #ccd7e9, #7085a5)
    background-size: contain
  .van-grid-item__content
    padding: 0
</style>
